const { constructMicroAppUrl } = useNavigation();

// Middleware function to redirect to home page in case user is not logged in
// or corresponding dashboard page for logged in user
export default defineNuxtRouteMiddleware(async (to, from) => {
  // Check for server side session
  if (process.server) {
    let isAuthenticated = false;

    const event = useRequestEvent();

    if (event.context.session?.extUserId) {
      isAuthenticated = true;
    }

    // let user access the page if not logged in
    if (isAuthenticated) return;

    // construct the full dashboard URL
    const redirectUrl = constructMicroAppUrl(
      ['login'],
      useRuntimeConfig().public.microapps['urlContracts'].onboarding
    );

    if (redirectUrl && to.path !== redirectUrl) {
      return navigateTo(redirectUrl, {
        external: true
      });
    }
  }
});
